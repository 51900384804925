.parent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: #606060;
  font-size: 14px;
  font-weight: 500;
}

.error {
  margin: 42px 0 0 0;
  color: #550000;
  font-weight: 600;
}